import React from 'react';

export default () => {
    return (
        <>
            <p>We are sincerely grateful to you for using our Service.&nbsp;</p>

            <p>We do as much as possible to make your experience of using newloveonline.com unforgettable so that you could enjoy the best quality service.&nbsp;</p>

            <p>It is important for us to ensure that you are maximally satisfied with the Service you use.&nbsp;</p>

            <p>If you have any suggestions/recommendations on how we can improve our Service, or if you have any questions, please contact us at <a href="mailto:support@newloveonline.com">support@newloveonline.com</a>.&nbsp;&nbsp;</p>

            <p>Our team will be glad to help you as soon as possible.</p>

            <p>We genuinely hope that our Service will bring you pleasure, and you will be able to find what you have joined us for.</p>

            <p>&nbsp;</p>

            <p>Please read these terms and conditions before using Our Service.</p>

            <p>&nbsp;</p>

            <p><strong>1. Interpretation and Definitions</strong></p>

            <p>&nbsp;</p>

            <p><strong>1.1. Interpretation</strong></p>

            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.</p>

            <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

            <p>&nbsp;</p>

            <p><strong>1.2. Definitions</strong></p>

            <p>For the purposes of these Terms and Conditions:</p>

            <p><strong>a) Account</strong>&nbsp;means a unique account created for You to access our Service or parts of our Service.</p>

            <p><strong>b) Company</strong>&nbsp;(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to newloveonline.com.</p>

            <p><strong>c) Content</strong>&nbsp;refers to content such as text, images, or other information that can be posted, uploaded, linked to, or otherwise made available by You, regardless of the form of that content.</p>

            <p><strong>d) Feedback</strong>&nbsp;means feedback, innovations, or suggestions sent by You regarding the attributes, performance, or features of our Service.</p>

            <p><strong>e) Goods (gifts)</strong> and services refer to the items and services offered for sale on the Service.</p>

            <p><strong>f) Orders</strong>&nbsp;mean a request by You to purchase Goods and services from Us.</p>

            <p><strong>g) Promotions</strong>&nbsp;refer to contests or other promotions offered through the Service.</p>

            <p><strong>h) Service</strong>&nbsp;refers to the Website.</p>

            <p><strong>i) Terms and Conditions</strong>&nbsp;(also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</p>

            <p><strong>j) Third-party Social Media Service</strong>&nbsp;means any services or content (including data, information, products, or services) provided by a third-party that may be displayed, included or made available by the Service.</p>

            <p><strong>k) The website</strong>&nbsp;refers to newloveonline.com, accessible from <a href="https://newloveonline.com/">https://newloveonline.com</a>.</p>

            <p><strong>l) You</strong>&nbsp;means the individual accessing or using the Service, as applicable.</p>

            <p>&nbsp;</p>

            <p><strong>2. Acknowledgment</strong></p>

            <p>2.1. These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</p>

            <p>2.2. Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users, and others who access or use the Service.</p>

            <p>2.3. By accessing or using the Service You automatically agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service and must not use it.</p>

            <p>2.4. Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>

            <p>&nbsp;</p>
            <p><strong>3. Description of Service</strong></p>

            <p>3.1. We refer to the Website and all services provided through the Website as the "Service". This is a communication website for men and women from all over the world. We charge users for utilizing online communication facilities. newloveonline.com has no personal relations with the women on the site. We DO NOT VOUCH for the ladies conduct, but we will do everything possible to make the service best for you.&nbsp;</p>

            <p>3.2. Our partners&nbsp;are strictly required to personally interview with each woman and take her passport copy before publishing her profile online.</p>

            <p>3.3. The website allows subscribers to get in touch with anyone who has posted a profile on the Website.&nbsp;</p>

            <p>3.4. You agree that we do not provide any guarantee or assurance that our Website or the Services will operate continuously or without interruptions or be error-free or be fit for any particular purpose. Accordingly, we cannot accept liability for unavailability of Our Service or the timeliness, deletion, misdelivery or failure to store any users' message chains, loss of information, or personalization settings or that the Service will meet your requirements.&nbsp;</p>

            <p>3.5. We will make a considerable effort to recover lost information. However, you take full responsibility and risk of your use of the Website and use of all information contained within it. Besides, keep in mind that we have the right to make changes or corrections, alter, suspend or discontinue any aspect of the Website or the content or Services available through it, including your access to it. Such changes or additions shall take effect within a reasonable amount of time. You are advised to check the Website every now and again to keep yourself up to date with any changes.</p>

            <p>3.6. We review content provided to us by our partners, but we cannot guarantee its 100% compliance. Before posting the Lady's ID on the website, our partners are required to conduct an interview, verify the lady's personality and her passport data. In addition, our partners provide the following services on our site: portfolio preparation, clarification of the use of the site, assistance in using the Service, assistance with translation of messages, gift delivery, organization of the meeting, etc.</p>

            <p>&nbsp;</p>

            <p><strong>4. Right to use</strong></p>

            <p>4.1. Your right to use the Service is subject to any limitations, conditions, and restrictions established by us from time to time, in our sole discretion.&nbsp;</p>

            <p>4.2. We may alter, suspend, or discontinue any aspect of the Service at any time, including the availability of any Service feature, database, or content. We may also impose limits on certain features and aspects of the Service or restrict your access to part/parts or all of the Service without notice or liability.&nbsp;</p>

            <p>4.3. The user is allowed to use the services of the Website for non-commercial purposes only and subject to her/ his strict compliance with these Terms.</p>

            <p>&nbsp;</p>

            <p><strong>5. Contact Data</strong></p>

            <p>5.1. All information to get in contact with somebody is considered as contact data.&nbsp;</p>

            <p>5.2. The user is not allowed to include contact data in fields or texts which are not explicitly intended for it. This applies in particular to the free text fields (About myself, Hobbies, etc). The user is not allowed to include contact data in photos uploaded to the Website. It is forbidden to pass on contact data to other users of the Website through texts or photos on a profile, messages, or online chat.&nbsp;</p>

            <p>5.3. You authorize us to use for our own purposes, including for promotional purposes, all public information from your profile (your opened information, photos, your reviews, etc.).&nbsp;</p>

            <p>5.4. To ensure the quality of the Service provided, Your communication through the Website may be recorded.</p>

            <p>&nbsp;</p>

            <p><strong>6. Adult</strong></p>

            <p>6.1. You represent, warrant, and covenant that you are at least 18 years old.&nbsp;</p>

            <p>6.2. By placing an Order for Goods and services through the Service, You warrant that You are legally capable of entering into binding contracts.</p>

            <p>&nbsp;</p>

            <p><strong>7. Currency</strong></p>

            <p>7.1. We use our virtual currency “Credits”. It is not real money, you can use Credits only on our Website.</p>

            <p>7.2. If you want to have Credits, you have to pay real money. Gift cards and certificates are not accepted.</p>

            <p>7.3. You can choose the position of automatic replenishment of your account on our site. For this you need when buying Credits agree to automatic monthly withdrawals from your credit/debit card.</p>

            <p>7.4. You use Credits for the following purposes: viewing content, chatting, sending emails, sending gifts, watching video presentations etc.</p>

            <p>7.5. The cost of Credits may vary at different times.</p>

            <p>7.6. Credits are spent as follows:</p>

            <p>a) The cost of one minute of chat correspondence - 2 Credits</p>

            <p>b) Send a sticker - 2 Credits</p>

            <p>c) Send or receive a photo (via letters/chat)- 10 Credits</p>

            <p>d) Send a letter - 20 Credits</p>

            <p>e) Send or receive an audio message&nbsp;(via letters/chat)&nbsp;- 15 Credits</p>

            <p>f) Send or receive a video message&nbsp;(via letters/chat)&nbsp;- 25 Credits</p>

            <p>g) To view a video show - 20 Credits</p>

            <p>h) To make a contact request - 500 Credits. Please be kindly informed that a member decides what kind of data they will provide you with&nbsp;(either an e-mail or a phone number).&nbsp;The option will be available after 4,000 Credits have been spent on that particular Profile, but only for chats, e-mails and media content.</p>

            <p>i) Organize a meeting (up to 3 hours of communication) - 0,0 Credits. The option will be available after you have received the member's contact information (an e-mail&nbsp;or a phone number). You can discuss all meeting details by yourself.</p>

            <p>j) Organize a meeting (up to 3 hours of communication) - 1,000 Credits. The option will be available after you have received the member's contact information (an e-mail or a phone number). Besides, the option includes an interpreter's service.</p>

            <p>7.7. The value of the gifts is indicated in the "Send Gift" tab.</p>

            <p>7.8. During the period of special offers, the cost of services may decrease.</p>

            <p>&nbsp;</p>
            <p><strong>8. Your Information</strong></p>

            <p>8.1. If You wish to buy Credits, you may be asked to supply certain information relevant to Your Order including, without limitation, Your credit/debit card number, the expiration date of Your credit/debit card, Your billing address, and Your shipping information.</p>

            <p>8.2. You represent and warrant that: (i) You have the legal right to use any credit or debit card(s) or another payment method (s) in connection with any Order; and that (ii) the information You supply to us is true, correct and complete.</p>

            <p>8.3. By submitting such information, You grant us the right to provide the information to payment processing third parties for purposes of facilitating the completion of Your Order.</p>

            <p>&nbsp;</p>

            <p><strong>9. Order Cancellation</strong></p>

            <p>9.1. We reserve the right to refuse or cancel Your Order at any time for certain reasons including but not limited to:</p>

            <p>a) Goods or services availability.</p>

            <p>b) Errors in the description or prices for Goods or services.</p>

            <p>c) Errors in Your Order.</p>

            <p>9.2. We reserve the right to refuse or cancel Your Order if fraud or an unauthorized or illegal transaction is suspected.</p>

            <p>&nbsp;</p>

            <p><strong>10. Your Order Cancellation Rights</strong></p>

            <p>10.1. Any Goods (gifts) or services you purchase can only be returned in accordance with these Terms and Conditions and Our Returns Policy.</p>

            <p>10.2. Our Return and Refund Policy forms a part of these Terms and Conditions. Please read our Returns Policy to learn more about your right to cancel Your Order.</p>

            <p>10.3. You may request a refund only within 30 calendar days of payment. In the event of a deadline, we may refuse to consider your request. All requests should be sent to <a href="mailto:support@newloveonline.com">support@newloveonline.com</a>.</p>

            <p>10.4. We will reimburse You no later than 14 days from the day on which We receive Your order cancellation notice.&nbsp;</p>

            <p>10.5. Be kindly informed, that first of all you get your Credits back.&nbsp;</p>

            <p>10.6. Money is only refundable upon the return of the Credits and upon a separate application.&nbsp;</p>

            <p>10.7. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.</p>

            <p>10.8. You will not have any right to cancel an Order for the supply of any of the following Goods or services:</p>

            <p>a) The supply of Goods or services made to Your specifications or clearly personalized.</p>

            <p>b) The supply of Goods or services which according to their nature are not suitable to be returned, deteriorate rapidly, or where the date of expiry is over.</p>

            <p>c) The supply of Goods or services which are not suitable for return due to health protection or hygiene reasons and were unsealed after delivery.</p>

            <p>d) The supply of Goods or services which are, after delivery, according to their nature, inseparably mixed with other items.</p>

            <p>&nbsp;</p>

            <p><strong>11. Availability, Errors and Inaccuracies</strong></p>

            <p>11.1. We are constantly updating Our offerings of Goods (gifts) and services on the <a href="https://newloveonline.com/">newloveonline.com</a>.&nbsp;</p>

            <p>11.2. The Goods (gifts) and services available on <a href="https://newloveonline.com/">newloveonline.com</a> may be mispriced, described inaccurately, or unavailable, and We may experience delays in updating information regarding our Goods&nbsp; (gifts) and services on the <a href="https://newloveonline.com/">newloveonline.com</a> and in Our advertising on other websites.</p>

            <p>11.3. We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services.&nbsp;</p>

            <p>11.4. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.</p>

            <p>
                &nbsp;</p>

            <p><strong>12. Prices Policy</strong></p>

            <p>12.1. The Company reserves the right to revise its prices at any time prior to accepting an Order.</p>

            <p>12.2. The prices quoted may be revised by the Company subsequent to accepting an Order in the event of any occurrence affecting delivery caused by government action, variation in Customs duties, increased shipping charges, higher foreign exchange costs, and any other matter beyond the control of the Company. In that event, You will have the right to cancel Your Order.</p>

            <p>&nbsp;</p>

            <p><strong>13. Fees</strong></p>

            <p>13.1. We take fees for viewing content, chatting, sending emails, sending gifts, watching video presentations etc.&nbsp;</p>

            <p>13.2. All prices are available for registered members in ‘Get Credits’ section.&nbsp;</p>

            <p>13.3. Payment can be made through various payment methods we have available, such as Visa, MasterCard, or online payment methods.</p>

            <p>13.4. Payment cards (credit cards or debit cards) are subject to validation checks and authorization by Your card issuer. If we do not receive the required authorization, We will not be liable for any delay or non-delivery of Your Order.</p>

            <p>13.5. We may change our fee schedule at any time and the new fee schedule shall be enforced immediately upon publication of the same on our Website or upon notice to you via email.&nbsp;</p>

            <p>13.6. Please read our Return and Refund Policy carefully before you use newloveonline.com and pay for the services as the Return and Refund Policy is an integral part of the current Terms and conditions.</p>

            <p>&nbsp;</p>

            <p><strong>14. Membership</strong></p>

            <p>14.1. There is no membership subscription at our Website. Your profile will be posted for free after getting through the registration procedure.&nbsp;</p>

            <p>14.2. By submitting your email, you agree to receive messages from our site.&nbsp;</p>

            <p>14.3. You also agree that you might be registered on newloveonline.com Partners Websites. By using their services, you agree with Terms and Conditions, Privacy Policy and other policies posted on their website.</p>

            <p>&nbsp;</p>

            <p><strong>15.&nbsp;Usage of the site&nbsp;</strong></p>

            <p>15.1.&nbsp;Usage of the Site and the Service is also governed by the Privacy Policy, the Return&nbsp;and Refund&nbsp;Policy. We ask you to read carefully the text of these documents.</p>

            <p>&nbsp;</p>

            <p><strong>16. Privacy and Use of information</strong></p>

            <p>16.1. By using the services, you have also agreed with our Privacy Policy.&nbsp;</p>

            <p>16.2. You acknowledge that:</p>

            <p>a) we cannot ensure the security or privacy of information you provide through the Internet and your email messages, and you release us from any and all liability in connection with the use of such information by other parties;</p>

            <p>b) we are not responsible for, and cannot control, the use by others of any information which you post on the Service or provide to them and you should use caution in selecting the personal information you post or provide to others through the Service;</p>

            <p>c) we cannot assume any responsibility for the content of messages sent by other users of the Service, and you release us from any and all liability in connection with the contents of any communications you may receive from other users.&nbsp;</p>

            <p>16.3. As part of the Service, we may from time to time, email to you publicly available information about other users of the Service whom we believe may meet your pre-selected search criteria.&nbsp;With your consent, you can also receive push notifications from time to time.</p>

            <p>16.4. We cannot guarantee, and assume no responsibility for verifying, the accuracy of the information provided by other users of the Service, this includes information which we may provide directly to you but which has been posted or provided to us by other users of the Service.</p>

            <p>&nbsp;</p>

            <p><strong>17. Communication on the site. Monitoring of information</strong></p>

            <p>17.1. We reserve the right to monitor all public postings, messages, video, and audio recordings to ensure that they conform to the content guidelines which may be applicable from time to time.&nbsp;</p>

            <p>17.2. Content we DO NOT allow: pedophilia, incest, zoophilia, graphic violence humiliation, blackmail, threats, harassment, psychological pressure, their imitation and/or promotion,&nbsp;and any other type of abusive, obscene, or illegal materials, any commercial activity (sweepstakes, barter, advertising, pyramid schemes, etc.).&nbsp;</p>

            <p>17.3. Website administration abides by a zero-tolerance policy towards any inappropriate content.&nbsp;</p>

            <p>17.4. You are solely responsible for your interaction with other newloveonline.com members.&nbsp;</p>

            <p>17.5. We reserve the right but are not obligated, to monitor disputes between you and other participants. Therefore, in case of a dispute, please contact us at <a href="mailto:support@newloveonline.com">support@newloveonline.com</a>.</p>

            <p>&nbsp;</p>

            <p><strong>18. Termination of Access to Service</strong></p>

            <p>18.1. We may, in our sole discretion, terminate or suspend your access to all or part of the Service at any time, with or without notice, for any reason, including, without limitation, breach of this Agreement.&nbsp;</p>

            <p>18.2. Without limiting the generality of the foregoing, any fraudulent, abusive, or otherwise illegal activity, or that may otherwise affect the enjoyment of the Service or the Internet by others may be grounds for termination of your access to all or part of the Service at our sole discretion, and you may be referred to appropriate law enforcement agencies.</p>

            <p>18.3. We may, in our sole discretion, deny or suspend the Members with certain IP-addresses access to the Site or to any of its parts anytime, with or without notice for any reason.</p>

            <p>&nbsp;</p>

            <p><strong>19. Proprietary information</strong></p>

            <p>19.1. The Service contains information which is proprietary to us, our partners, and our users.&nbsp;</p>

            <p>19.2. We assert full copyright protection in the Service. Information posted by us, our partners, or users of the Service may be protected whether or not it is identified as proprietary to us or to them.&nbsp;</p>

            <p>19.3. You agree not to modify, copy, or distribute any such information in any manner whatsoever without having first received the express permission of the owner of such information.</p>

            <p>&nbsp;</p>

            <p><strong>20. Security</strong></p>

            <p>20.1. Your account is private and should not be used by anyone else.&nbsp;</p>

            <p>20.2. You are not allowed to share your login and password with any other person.&nbsp;</p>

            <p>20.3. You are responsible for all usage or activity on the Service by users using your password, including but not limited to use your password by any third party.&nbsp;</p>

            <p>
                &nbsp;</p>

            <p><strong>21. Disclaimer</strong></p>

            <p>21.1. newloveonline.com is a communication website that operates as an intermediary between men and women all over the world.&nbsp;</p>

            <p>21.2. We take fees for viewing content, chatting, sending emails, sending gifts, watching video presentations, etc.&nbsp;</p>

            <p>21.3. We do not offer any marriage services and do not guarantee that the membership with us will&nbsp;help to find your soulmate.&nbsp;</p>

            <p>21.4. newloveonline.com staff has no personal relations with the women you see on the site.&nbsp;</p>

            <p>21.5. Our partners are strictly required to personally interview each woman and take her passport copy before publishing her profile online. Though each partner is demanded to render top-quality services, some may fail to provide the desired quality of services.&nbsp;</p>

            <p>21.6. The woman are all unique individuals with different personalities and attitudes. Hence, their conduct cannot be guaranteed and cannot be a ground for claiming a refund.&nbsp;</p>

            <p>21.7. Neither credits nor money will be returned due to dissatisfaction with the content of the incoming letters, letter attachments, instant messages, messages in chats, video, or any deviance of female profile information.&nbsp;</p>

            <p>21.8. If you encounter any problem with a particular lady, please be advised that you have to inform us of Lady ID at <a href="mailto:support@newloveonline.com">support@newloveonline.com</a>, so that we can carry out an investigation and handle the case effectively.</p>

            <p>21.9. If any participant asks you for financial help, or to buy gifts, please let us know at <a href="mailto:support@newloveonline.com">support@newloveonline.com</a>.</p>

            <p>&nbsp;</p>

            <p><strong>22. Cookie Policy</strong></p>

            <p>22.1. This Cookie Policy describes what cookies and similar technologies are, what types of cookies are placed on your device when you visit the Website and how we use them.&nbsp;</p>

            <p>22.2. This Cookie Policy does not address how we deal with your personal data generally. To learn more about how we process your personal data, please see our Privacy Policy. We encourage you to read this policy so that you can understand what information is collected when you visit or interact with the Website, and how we or our service providers may use cookies, web beacons, and other similar technologies for storing information about your interactions with the Website to help provide you with a better, faster, and safer experience and for advertising purposes. Please check the Terms of Use for the meaning of defined words (those with capital letters) not explicitly defined in this Cookie Policy.&nbsp;</p>

            <p>22.3. You are not permitted to collect and use personal data of other members in any way.</p>

            <p>22.4. What are cookies?</p>

            <p>Cookies are text files, containing small amounts of technically relevant information, which are downloaded mostly to/by a web browser on your device (such as a computer or smartphone, etc.) when you visit a website. Cookies can be recognized by the website that downloaded them or other websites that use the same cookies.&nbsp;</p>

            <p>22.5. What are web beacons?</p>

            <p>Some of our web pages may contain electronic images known as web beacons (sometimes also called clear gifs or pixel tags). Web beacons are usually small images located on a webpage or email that you are viewing. The request that your Internet-connected device makes to fetch the image from the server/computer is recorded and provides us with information such as the IP address of the computer wanting to view the image, the time the image was viewed, and the type of browser viewing the image. For example, through that technology we can see if an email or advertisement has been opened and acted upon. In means, that cookies work together with web beacons to accomplish the functions and purposes described in this Cookie Policy.&nbsp;</p>

            <p>22.6. What are cookies used for?</p>

            <p>Cookies do lots of different jobs. They adapt the Website according to previously chosen preferences, help us understand how the Website is being used, let you navigate between pages efficiently, and help to improve your browsing experience in general. Cookies can also help ensure advertisements you see online are more relevant to your interests.&nbsp;</p>

            <p>22.7. Types of cookies we use</p>

            <p>The types of cookies used on most websites can generally be put into one of the following categories: strictly necessary, analytics, functionality, advertising, social media, and fraud prevention. In order to provide you with the best browsing experience, we and our third-party partners may use cookies from all of these categories. You can find out more about the cookies we use from each of the categories in the list below.</p>

            <p>22.8. Strictly Necessary Cookies</p>

            <p>These cookies are essential to make the Website work on your browsing device. They enable you to pass security checks and navigate through our Website and use its features and the Services. Without these cookies the use of the Website and the Services, that are necessary for you, can't be provided in principle or at a decent level.</p>

            <p>22.9. Analytics Cookies</p>

            <p>These cookies collect information about how Users and Members use the Website. For example, which pages are visited most often, how Users and Members move from one link to another, and whether they get error messages from certain pages. All in all, these cookies provide us with analytical information about how the Website works and how we can make it more comfortable and perfect.</p>

            <p>22.10. Functionality Cookies</p>

            <p>These cookies allow us to remember the choices you make and tailor our Website to provide enhanced features and content to you. For example, these cookies can be used to remember your username, language choice, or country selection and they can also be used to remember changes you have made to text size, font, and other parts of pages that you have previously customized.</p>

            <p>22.11. Advertising Cookies</p>

            <p>These сookies are being used to deliver advertisements on our Website, on other sites around the Web to users that have visited or used our Website or the Services, or to deliver advertisements about the Services that are relevant to you and your interests. These cookies also limit the number of times you see an advertisement as well as help measure and report the results and effectiveness of an advertising campaign. They remember that you have visited the Website and this information may be shared with other organizations such as advertisers. This means after you have visited our Website you may see advertisements about our Services or other services elsewhere on the Internet.</p>

            <p>22.12. Social Media Cookies</p>

            <p>To enhance your Internet experience and to make the sharing of content easier, some of the Website’s pages contain tools that are linked to third party social media service providers such as Facebook or Twitter. To enable automatic information exchange between us and these providers you will need to use a respective button, menu option, etc. Through these tools, third-party service providers, i.e. social media networks may set their own cookies on your device. We do not control these cookies and you should check these social media service provider's websites for further details about how they use cookies and how to control them.</p>

            <p>22.13. Fraud Prevention Cookies</p>

            <p>These cookies, flash storage tokens, smart phone SDK applications, or other software on the end user's device contain a unique identifier. Subsequently, in transactions between you and us, that identifier is accessed and checked through third party databases to determine if the device has been associated with suspected fraud or abuse reported by other online service providers in connection with prior transactions involving that device. How long will cookies stay on the device? The length of time a cookie will stay on your browsing device depends on whether it is a "persistent" or "session" cookies. Session cookies will only stay on your device until you stop browsing. Persistent cookies stay on your device after you have finished browsing and until they expire or are deleted.</p>

            <p>22.14. First and third-party cookies</p>

            <p>First-party cookies are cookies that belong to us and are mostly used to adapt the Website interface according to your preferences and for other purely technical reasons, like authorization, correct work of modules, etc. Third-party cookies are cookies that the third-party service providers/our partners place on your browsing device when you visit our Website and/or use our Services. These are used for more appropriate advertising, different analytics. Both first and third-party cookies can be analytics, functionality, and advertising cookies.</p>

            <p>22.15. How to manage first-party Cookies</p>

            <p>You are able to adjust the browser that you are using for our Website and the Services and enable, disable or delete Cookies. To do this, follow the instructions provided by your browser (usually located within the "Help", "Tools" or "Edit" settings). Please note that if you set your browser to disable cookies, you may not be able to access the secure areas of the Website and other parts of the Website may also not work properly. You may also be able to opt-out of some targeted advertising on your mobile device by selecting "limit ad tracking" (iOS) or "opt-out of interest-based ads" (Android). Here you may find more information on how to manage cookies in your browser:</p>

            <p><strong>Chrome:</strong> <a href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a>&nbsp;</p>

            <p><strong>Explorer:</strong> <a href="https://support.microsoft.com/en-us/products/windows?os=windows-10">https://support.microsoft.com/en-us/products/windows?os=windows-10</a>&nbsp;</p>

            <p><strong>Safari:</strong> <a href="https://support.apple.com/kb/PH21411">https://support.apple.com/kb/PH21411</a>&nbsp;</p>

            <p><strong>Firefox:</strong> <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a>&nbsp;</p>

            <p><strong>Opera:</strong> <a href="http://www.opera.com/help/tutorials/security/cookies/">http://www.opera.com/help/tutorials/security/cookies/</a>&nbsp;</p>

            <p>22.16. How to manage third-party Cookies</p>

            <p>Cookies that are placed on your browsing device by a third party while you are viewing our Website and may be managed through your browser settings (as described above), by selecting the Do Not Track option on your browser (though we have no control over and cannot confirm whether these third-party ad networks respond to the Do Not Track browser signal), or by checking the third party's website for more information about cookie management and how to "opt-out" of receiving cookies from them. For more information about third-party cookies used on our Website, please refer to the respective provider’s policies that we cooperate with. We also allow advertising networks to deliver cookies when you are viewing our Website and applications or ads on our Services, and these advertising partners may include Yahoo's Flurry service and may change from time to time. Analytics and advertising - Google, Yahoo, Bing etc.&nbsp;</p>

            <p>&nbsp;</p>

            <p><strong>23. Promotions</strong></p>

            <p>23.1. Any Promotions made available through the Service may be governed by rules that are separate from these Terms.</p>

            <p>23.2. If You participate in any Promotions, please review the applicable rules as well as our Privacy policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.</p>

            <p>&nbsp;</p>

            <p><strong>24. User Accounts</strong></p>

            <p>24.1. When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service.</p>

            <p>24.2. You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.</p>

            <p>24.3. You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.</p>

            <p>24.4. You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>

            <p>&nbsp;</p>

            <p><strong>25. Content</strong></p>

            <p>25.1. Our Service allows You to post Content.&nbsp;</p>

            <p>25.2. You are responsible for the Content that You post to the Service, including its legality, reliability, and appropriateness.</p>

            <p>25.3. You retain any and all of Your rights to any Content You submit, post, or display on or through the Service and You are responsible for protecting those rights.&nbsp;</p>

            <p>25.4. By posting Content to the Service, You grant Us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service.&nbsp;</p>

            <p>25.5. You agree that this license includes the right for Us to make Your Content available to other users of the Service, who may also use Your Content subject to these Terms.</p>

            <p>25.6. You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.</p>

            <p>&nbsp;</p>

            <p><strong>26. Content Restrictions</strong></p>

            <p>26.1. The Company is not responsible for the content of the Service's users.&nbsp;</p>

            <p>26.2. You expressly understand and agree that You are solely responsible for the Content and for all activity that occurs under your account, whether done so by You or any third person using Your account.</p>

            <p>26.3. You may not transmit any Content that is unlawful, offensive, pornographic, erotic, upsetting, intended to disgust, threatening, libelous, defamatory, obscene, or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the following:</p>

            <p>a) Unlawful or promoting unlawful activity.</p>

            <p>b) Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.</p>

            <p>c) Spam, machine – or randomly-generated, constitutes unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.</p>

            <p>d) Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person.</p>

            <p>e) Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity, or other rights.</p>

            <p>f) Impersonating any person or entity including the Company and its employees or representatives.</p>

            <p>g) Violating the privacy of any third person.</p>

            <p>h) False information and features.</p>

            <p>26.4. The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not any Content is appropriate and complies with this Terms, refuse or remove this Content.&nbsp;</p>

            <p>26.5. The Company further reserves the right to make formatting and edits and change the manner of any Content. The Company can also limit or revoke the use of the Service if You post such objectionable Content.</p>

            <p>26.6. As the Company cannot control all Content posted by users and/or third parties on the Service, you agree to use the Service at your own risk. You understand that by using the Service You may be exposed to content that You may find offensive, indecent, incorrect or objectionable, and You agree that under no circumstances will the Company be liable in any way for any content, including any errors or omissions in any content, or any loss or damage of any kind incurred as a result of your use of any content.</p>

            <p>&nbsp;</p>
            <p><strong>27. Content Backups</strong></p>

            <p>27.1. Although regular backups of Content are performed, the Company does not guarantee there will be no loss or corruption of data.</p>

            <p>27.2. Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted prior to being backed up or that changes during the time a backup is performed.</p>

            <p>27.3. The Company will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of Content.&nbsp;</p>

            <p>27.4. You acknowledge that the Company has no liability related to the integrity of Content or the failure to successfully restore Content to a usable state.</p>

            <p>27.5. You agree to maintain a complete and accurate copy of any Content in a location independent of the Service.</p>

            <p>&nbsp;</p>

            <p><strong>28. Copyright Policy</strong></p>

            <p>28.1. We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content posted on the Service infringes a copyright or other intellectual property infringement of any person.</p>

            <p>28.2. If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, You must submit Your notice in writing to the attention of our copyright agent via email at&nbsp;<a href="mailto:support@newloveonline.com">support@newloveonline.com</a> and include in Your notice a detailed description of the alleged infringement.</p>

            <p>28.3. You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing Your copyright.</p>

            <p>&nbsp;</p>

            <p><strong>29. DMCA Notice and DMCA Procedure for Copyright Infringement Claims</strong></p>

            <p>29.1. You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):</p>

            <p>a) An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest.</p>

            <p>b) A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work.</p>

            <p>c) Identification of the URL or other specific location on the Service where the material that You claim is infringing is located.</p>

            <p>d) Your address, telephone number, and email address.</p>

            <p>e) A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</p>

            <p>f) A statement by You, made under penalty of perjury, that the above information in Your Notice is accurate and that You are the copyright owner or authorized to act on the copyright owner's behalf.</p>

            <p>29.2. You can contact our copyright agent via email at&nbsp;<a href="mailto:support@newloveonline.com">support@newloveonline.com</a>.</p>

            <p>29.3. Upon receipt of a notification, the Company will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the Service.</p>

            <p>&nbsp;</p>

            <p><strong>30. Intellectual Property</strong></p>

            <p>30.1. The Service and its original content (excluding Content provided by you or other users, site design, including stickers, users' profile photos, male profile photos, female profile photos, intro videos, users' video presentations, all text on the site, etc.), features and functionality is and will remain the exclusive property of the Company and its licensors.</p>

            <p>30.2. The Service is protected by copyright, trademark, and other laws of both the Country of origin and foreign countries.</p>

            <p>30.3. Our trademarks must not be used in connection with any product or service without the prior written consent of the Company.</p>

            <p>30.4. Our Website, located at <a href="https://newloveonline.com/">https://newloveonline.com</a>,&nbsp; belongs to CLARENCE GENERAL PROJECTS LLP, address: 21 Botanic Avenue, Suite 15, Belfast, Northern Ireland, BT7 1JJ, company number NC001485.</p>

            <p>30.5. In case of infringement of intellectual property rights, you are liable for $ 10,000 for each infringement. You will also be liable for damages, including non-pecuniary and reputational damages, legal costs and attorneys’ fees.</p>

            <p>30.6. All disputes are referred to and finally resolved by arbitration under the LCIA Rules (London Court of International Arbitration), which Rules are deemed to be incorporated by reference into this clause.</p>

            <p>The number of arbitrators shall be one.</p>

            <p>The seat, or legal place of arbitration shall be London.</p>

            <p>The language to be used in the arbitral proceedings shall be English.</p>

            <p>The governing law shall be the substantive law of the United Kingdom.</p>

            <p>&nbsp;</p>

            <p><strong>31. Your Feedback to Us</strong></p>

            <p>31.1. You assign all rights, title, and interest in any Feedback You provide the Company.&nbsp;</p>

            <p>31.2. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty-free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.</p>

            <p>&nbsp;</p>

            <p><strong>32. Links to Other Websites</strong></p>

            <p>32.1. Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>

            <p>32.2. The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services.&nbsp;</p>

            <p>32.3. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>

            <p>32.4. We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</p>

            <p>&nbsp;</p>

            <p><strong>33. Termination</strong></p>

            <p>33.1. We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.</p>

            <p>33.2. Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, you can contact us at <a href="mailto:support@newloveonline.com">support@newloveonline.com</a>. Your account will be deleted immediately after the reception of your request.</p>

            <p>&nbsp;</p>

            <p><strong>34. Limitation of Liability</strong></p>

            <p>34.1. Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.</p>

            <p>34.2. To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>

            <p>34.3. Some states (Countries) do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>

            <p>&nbsp;</p>

            <p><strong>35. "AS IS" and "AS AVAILABLE" Disclaimer</strong></p>

            <p>35.1. The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind.&nbsp;</p>

            <p>35.2. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice.&nbsp;</p>

            <p>35.3. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error-free or that any errors or defects can or will be corrected.</p>

            <p>35.4. Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>

            <p>35.5. Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p>

            <p>&nbsp;</p>

            <p><strong>36. Governing Law</strong></p>

            <p>36.1. Any dispute shall be referred to and finally resolved by arbitration under the LCIA Rules (London Court of International Arbitration), which Rules are deemed to be incorporated by reference into this clause.</p>

            <p>36.2. The number of arbitrators shall be one.</p>

            <p>36.3. The seat, or legal place, of arbitration, shall be London.</p>

            <p>36.4. The language to be used in the arbitral proceedings shall be English.</p>

            <p>36.5. The governing law shall be the substantive law of the United Kingdom.</p>

            <p>&nbsp;</p>

            <p><strong>37. Disputes Resolution</strong></p>

            <p>37.1. If You have any concerns or disputes about the Service, You agree to first try to resolve the dispute informally by contacting the Company at <a href="mailto:support@newloveonline.com">support@newloveonline.com</a>.</p>

            <p>37.2. United States Legal Compliance:</p>

            <p>You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a “terrorist supporting” country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>

            <p>&nbsp;</p>

            <p><strong>38. Severability and Waiver</strong></p>

            <p>38.1. Severability:</p>

            <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>

            <p>38.2. Waiver:</p>

            <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Terms shall not affect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.</p>

            <p>&nbsp;</p>

            <p><strong>39. Translation Interpretation</strong></p>

            <p>39.1. These Terms and Conditions may have been translated if We have made them available to You on our Service.</p>

            <p>39.2. You agree that the original English text shall prevail in the case of a dispute.</p>

            <p>&nbsp;</p>

            <p><strong>40. Changes to These Terms and Conditions</strong></p>

            <p>40.1. We reserve the right, at Our sole discretion, to modify or replace these Terms at any time.&nbsp;</p>

            <p>40.2. By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms.&nbsp;</p>

            <p>40.3. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>

            <p>&nbsp;</p>

            <p><strong>41. Contact Us</strong></p>

            <p>41.1. If you have any questions about these Terms and Conditions, You can contact us:</p>

            <p>By email:<a href="mailto:support@newloveonline.com">support@newloveonline.com</a>.</p>

            <p>By visiting this page on our website:&nbsp;<a href="https://newloveonline.com/support">https://newloveonline.com/support</a>.</p>

            <p>&nbsp;</p>

            <p>Last updated: August 20, 2020</p>
        </>
    )
}