import React from "react";
import * as IMAGES from "../../../../img";
import * as REGISTRATION from "../../../../../node_modules/lnd-worker/src/constants/registration";

export default ({active = false, setActive, formData, setFormData, saveHistory}) => {

    const onSetGender = (value) => {
        setFormData({ ...formData, ...{ gender: value } });
    }

    return (
        <>
            <div className={`box ${active ? 'active' : 'box_hidden'}`}>
                <div className="banner-box__cap">
                    <div className="title-site title-site_h2-new">True Love is a Click Away!</div>
                </div>
                <div className="banner-box__body banner-box__body_wrapp1 banner-box__body_alone">
                    <div className="box-preview">
                        <div className="box-preview__cap">
                            <img src={IMAGES.default.image1} alt="image1"/>
                        </div>
                        <div className="first-block__box-preview__desc">Dating For Men Over 45!</div>
                        <div className="first-block__box-preview__desc-text">Chat With Single Ukrainian And Russian Women Now</div>
                        <button type="button" className="btn btn_lg btn_bold tabs-next-novalidate first-block__box-preview__next_btn"
                            onClick={() => {
                                onSetGender(REGISTRATION.GENDER_MALE);
                                setActive();
                            }}
                        >
                            OK
                        </button>
                        <div className="first-block__box-preview__desc-free">FREE REGISTRATION</div>
                        <div className="first-block__box-preview__desc-no-reg">no credit card required</div>
                    </div>
                </div>
            </div>
        </>
    )
}