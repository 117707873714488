import React, {useState} from "react";
import axios from 'axios';

import SmallSelect from '../../../SmallSelect';
import LargeSelect from '../../../LargeSelect';
import '../../../../styles/select.css';

import * as IMAGES from '../../../../img';
import * as SOCIAL from 'lnd-worker/src/constants/social';

function getArrayOfNumbers(start, stop) {
    const result = [];
    while (start <= stop) {
        result.push(start++);
    }
    return result;
}

export function checkEmail(email = null) {
    if (email === null) return false;
    // eslint-disable-next-line
    let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(email);
}

export function CheckEmailAPI(email) {
    return axios.post(`${SOCIAL.PATH_MAIN_DOMAIN}/api/login/checkEmail`, {
        email: email
    }).then(res => res.data);
}

export function Register({email, password, bday, bmonth, byear, looking, who}) {
    let tail = window.location.search;
    return axios.post(`${SOCIAL.PATH_MAIN_DOMAIN}/api/login/registerLanding`, {
        email, password, looking, who,
        day: bday,
        month: bmonth,
        year: byear,
        user_real_name: email?.split('@')[0] ?? '',
        tail: tail,
    }).then(res => res.data);
}

export function submitForm(email, password) {
    let form = document.createElement('form');
    document.body.appendChild(form);
    form.method = 'post';
    form.action = `${SOCIAL.PATH_MAIN_DOMAIN}/landing/landing_login${window.location.search.substring()}`;
    let input = document.createElement('input');
    let input2 = document.createElement('input');
    let input3 = document.createElement('input');
    let input4 = document.createElement('input');
    input.type = 'hidden';
    input2.type = 'hidden';
    input3.type = 'hidden';
    input4.type = 'hidden';
    input.name = 'email';
    input2.name = 'password';
    input3.name = 'utm_land';
    input4.name = 'tail';
    input.value = email;
    input2.value = password;
    input3.value = getUrlParameter('utm_land');
    input4.value = getTail();
    form.appendChild(input);
    form.appendChild(input2);
    form.appendChild(input3);
    form.appendChild(input4);
    form.submit();
}
export function checkDate(year, month, day) {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const dob = new Date(year, month - 1, day);
    const dobnow = new Date(today.getFullYear(), dob.getMonth(), dob.getDate());
    let age;

    age = today.getFullYear() - dob.getFullYear();

    if (today < dobnow) {
        age = age - 1;
    }

    return +age >= 18;
}
export function getUrlParameter(sParam) {
    let sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
}

export function getTail() {
    return window?.location?.search ?? '';
}

export default ({formData, setFormData}) => {
    const [blockModal, setBlockModal] = useState(0);
    const [emailError, setEmailError] = useState('');
    const [emailAlreadyUse, setEmailAlreadyUse] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

    const validateEmail = (email) => {
        if (!checkEmail(email)) {
            setEmailError('invalid');
            return false;
        } else {
            setEmailError('');
            return true;
        }
    };
    const continueClick = () => {
        validateEmail(formData.email) && new CheckEmailAPI(formData.email).then(res => {
            if (!res.status) {
                setBlockModal(+blockModal + 1)
            } else {
                setEmailAlreadyUse(true);
            }
        });
    };

    const handleRegister = () => {
        if (formData.password.length < 6) {
            setPasswordError(true);
            setPasswordErrorMessage('Minimum 6 characters.');
            return false;
            // eslint-disable-next-line no-useless-escape
        } else if(formData.password.search(/[,~!@#$%^(){}'`+=:;/?\\*"\[\]<>|]+/) >= 0){
            setPasswordError(true);
            setPasswordErrorMessage('Doesn\'t have to contain special characters.');
            return false;
        }else {
            setPasswordError(false);
        }
        setBlockModal(999);
        Register(formData).then(res => {
            if (res.status) {
                submitForm(formData.email, formData.password);
            } else {
                window.location.href = '/';
            }
        });
    };

    return (
        <div className="main-modal">
            <div className="main-modal__wrapp">
                {(function () {
                    switch (blockModal) {
                        case 0:
                            return <form action="#" className="main-modal__box modal-box">
                                <div className="modal-box__item">
                                    <div className="modal-box__tt">I am a</div>
                                    <div className="first_block">
                                        <LargeSelect
                                            formData={['Man', 'Woman'][formData.who]}
                                            handleClick={(item) => setFormData({...formData, ...{who: item}})}
                                            arrayFields={['Man', 'Woman']}
                                        />
                                    </div>
                                </div>
                                <div className="modal-box__item">
                                    <div className="modal-box__tt">I am looking for</div>
                                    <div className="first_block">
                                        <LargeSelect
                                            formData={['Man', 'Woman'][formData.looking]}
                                            handleClick={(item) => setFormData({...formData, ...{looking: item}})}
                                            arrayFields={['Man', 'Woman']}
                                        />
                                    </div>
                                </div>
                                <div className="modal-box__item modal-box__item_btn"
                                     onClick={() => setBlockModal(+blockModal + 1)}>
                                    <input type="button" value="Begin" className="btn btn_light btn_sm"/>
                                </div>
                            </form>;
                        case 1:
                            return <form action="#" className="main-modal__box modal-box">
                                <div className="cl2h_tab_wide_filter_item_wrap">
                                    <SmallSelect
                                        index="month"
                                        formData={formData?.bmonth}
                                        defaultName={'MM'}
                                        arrayFields={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']}
                                        handleClick={(item, key) => setFormData({...formData, ...{bmonth: +key + 1}})}
                                    />
                                    <SmallSelect
                                        formData={formData?.bday}
                                        defaultName={"DD"}
                                        arrayFields={getArrayOfNumbers(1, 31)}
                                        handleClick={(item) => setFormData({...formData, ...{bday: item}})}
                                    />
                                    <SmallSelect
                                        formData={formData?.byear}
                                        defaultName={'YYYY'}
                                        arrayFields={getArrayOfNumbers(1920, new Date().getFullYear() - 18)}
                                        handleClick={(item) => setFormData({...formData, ...{byear: item}})}
                                    />
                                </div>
                                <div className="modal-box__item modal-box__item_btn"
                                     onClick={() => formData?.bmonth && formData?.byear && formData?.bday && checkDate(formData.byear, formData.bmonth, formData.bday) && setBlockModal(+blockModal + 1)}>
                                    <input type="button" value="Begin" className="btn btn_light btn_sm"/>
                                </div>
                            </form>;
                        case 2:
                            return <form action="#" className="main-modal__box modal-box">
                                <div className="content_block_inside">
                                    <div className="modal-box__tt">Enter your <b>email</b></div>
                                    <input type="email" className={`content_block_mail_input ${emailError}`}
                                           placeholder="my_name@gmail.com" id="email_inp"
                                           autoComplete="off" value={formData.email}
                                           onChange={(e) => setFormData({...formData, ...{email: e.target.value}})}/>
                                    {
                                        emailAlreadyUse
                                        && <p id="email_already"
                                              style={{paddingTop: '5px'}}>
                                            Email Already In Use.
                                        </p>
                                    }
                                    {
                                        emailError === 'invalid'
                                        && <p id="email_already2"
                                              style={{paddingTop: '5px'}}>
                                            Please Enter Valid Email.
                                        </p>
                                    }
                                </div>
                                <div className="modal-box__item modal-box__item_btn"
                                     onClick={continueClick}>
                                    <input type="button" value="Begin" className="btn btn_light btn_sm"/>
                                </div>
                            </form>;
                        case 3:
                            return <form action="#" className="main-modal__box modal-box">
                                <div className="content_block_inside">
                                    <div className="modal-box__tt">Fill your <b>pass</b></div>
                                    <input type="text"
                                           value={formData.password}
                                           onChange={(e) => setFormData({...formData, ...{password: e.target.value}})}
                                           id="input_password" className="content_block_mail_input"
                                           placeholder="My Password" autoComplete="off"
                                    />
                                    {passwordError &&
                                    <p className={`red_color`}  style={{paddingTop: '5px'}} id="password_issue">{passwordErrorMessage}</p>}
                                </div>
                                <div className="modal-box__item modal-box__item_btn"
                                     onClick={handleRegister}>
                                    <input type="button" value="Begin" className="btn btn_light btn_sm"/>
                                </div>
                            </form>;
                        default:
                            return <form action="#" className="main-modal__box modal-box">
                                <img src={IMAGES.default.loading} alt={'Loading'} className={'loader'}/>
                            </form>
                    }
                })()}
            </div>
        </div>
    )
}