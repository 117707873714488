import React from 'react';

export default () => {
    return (
        <>
            <p></p><p>We are sincerely grateful to you for using our Service.&nbsp;</p>

            <p>We do as much as possible to make your experience of using newloveonline.com unforgettable so that you could enjoy the best quality service.&nbsp;</p>

            <p>It is important for us to ensure that you are maximally satisfied with the Service you use.&nbsp;</p>

            <p>If you have any suggestions/recommendations on how we can improve our Service, or if you have any questions, please contact us at <a href="mailto:support@newloveonline.com">support@newloveonline.com</a>.&nbsp;&nbsp;</p>

            <p>Our team will be glad to help you as soon as possible.</p>

            <p>We genuinely hope that our Service will bring you pleasure, and you will be able to find what you have joined us for.</p>

            <p>&nbsp;</p>

            <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

            <p>We use your data to provide and improve the Service.&nbsp;</p>

            <p><strong><u>By using the Service, you agree to the collection and use of information in accordance with this policy.&nbsp;</u></strong></p>

            <p>Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from <a href="https://newloveonline.com/">https://newloveonline.com</a><u>.</u>&nbsp;</p>

            <p>newloveonline.com&nbsp;("us", "we", or "our") operates the&nbsp;<a href="https://newloveonline.com/">https://newloveonline.com</a>&nbsp; website (hereinafter referred to as the "Service").</p>

            <p>&nbsp;</p>

            <p><strong>1. Definitions</strong></p>

            <p><strong>1.1. Service</strong></p>

            <p>Service is the <a href="https://newloveonline.com/">https://newloveonline.com</a> website</p>

            <p><strong>1.2. Personal Data</strong></p>

            <p>Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>

            <p><strong>1.3. Usage Data</strong></p>

            <p>Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>

            <p><strong>1.4. Cookies</strong></p>

            <p>Cookies are small files stored on your device (computer or mobile device).</p>

            <p><strong>1.5. Data Controller</strong></p>

            <p>Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed.</p>

            <p>For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.</p>

            <p><strong>1.6. Data Processors (or Service Providers)</strong></p>

            <p>Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller.</p>

            <p>We may use the services of various Service Providers in order to process your data more effectively.</p>

            <p><strong>1.7. Data Subject (or User)</strong></p>

            <p>Data Subject is any living individual who is using our Service and is the subject of Personal Data.</p>

            <p>&nbsp;</p>

            <p><strong>2. Information Collection and Use</strong></p>

            <p>2.1. We collect several different types of information for various purposes to provide and improve our Service to you.</p>

            <p>&nbsp;</p>

            <p><strong>3. Types of Data Collected</strong></p>

            <p><strong>3.1. Personal Data</strong></p>

            <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally, identifiable information may include, but is not limited to:</p>

            <p>a) Email address.</p>

            <p>b) First name and last name.</p>

            <p>c) Phone number.</p>

            <p>d) Address, State, Province, ZIP/Postal code, City.</p>

            <p>e) Cookies and Usage Data.</p>

            <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt-out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send or by contacting us.</p>

            <p><strong>3.2. Usage Data</strong></p>

            <p>We may also collect information on how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>

            <p><strong>3.3. Location Data</strong></p>

            <p>We may use and store information about your location if you give us permission to do so ("Location Data"). We use this data to provide features of our Service, to improve and customize our Service.</p>

            <p>You can enable or disable location services when you use our Service at any time by way of your device settings.</p>

            <p>&nbsp;</p>

            <p><strong>4. Tracking &amp; Cookies Data</strong></p>

            <p>4.1. We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p>

            <p>4.2. Cookies are files with a small amount of data which may include an anonymous unique identifier.&nbsp;</p>

            <p>4.3. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>

            <p>4.4. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>

            <p>4.5. Examples of Cookies we use:</p>

            <p><strong>a) Session Cookies.</strong>&nbsp;We use Session Cookies to operate our Service.</p>

            <p><strong>b) Preference Cookies.</strong>&nbsp;We use Preference Cookies to remember your preferences and various settings.</p>

            <p><strong>c) Security Cookies.</strong>&nbsp;We use Security Cookies for security purposes.</p>

            <p>&nbsp;</p>

            <p><strong>5. Use of Data</strong></p>

            <p>5.1. newloveonline.com uses the collected data for various purposes:</p>

            <p>a) To provide and maintain our Service.</p>

            <p>b) To notify you about changes to our Service.</p>

            <p>c) To allow you to participate in interactive features of our Service when you choose to do so.</p>

            <p>d) To provide customer support.</p>

            <p>e) To gather analysis or valuable information so that we can improve our Service.</p>

            <p>f) To monitor the usage of our Service.</p>

            <p>g) To detect, prevent and address technical issues.</p>

            <p>h) To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.</p>

            <p>&nbsp;</p>

            <p><strong>6. Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)</strong></p>

            <p>6.1. If you are from the European Economic Area (EEA), newloveonline.com legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.</p>

            <p>6.2. newloveonline.com may process your Personal Data because:</p>

            <p>a) We need to fulfill our obligations to you.</p>

            <p>b) You have given us permission to do so.</p>

            <p>c) The processing is in our legitimate interests and it is not overridden by your rights.</p>

            <p>d) For payment processing purposes.</p>

            <p>e) To comply with the law.</p>

            <p>&nbsp;</p>

            <p><strong>7. Retention of Data</strong></p>

            <p>7.1. newloveonline.com will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy.&nbsp;</p>

            <p>7.2. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies.</p>

            <p>7.3. newloveonline.com will also retain Usage Data for internal analysis purposes.&nbsp;</p>

            <p>7.4. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer periods.</p>

            <p>&nbsp;</p>

            <p><strong>8. Transfer of Data</strong></p>

            <p>8.1. Your information, including Personal Data, may be transferred to - and maintained on - computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>

            <p>8.2. We also inform you that servers can be transferred to other countries without notice.</p>

            <p><strong><u>8.3. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</u></strong></p>

            <p>&nbsp;</p>

            <p><strong>9. Disclosure of Data</strong></p>

            <p>9.1. Disclosure for Law Enforcement</p>

            <p>Under certain circumstances, newloveonline.com may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>

            <p>9.2. Legal Requirements</p>

            <p>newloveonline.com may disclose your Personal Data in the good faith belief that such action is necessary to:</p>

            <p>a) To comply with a legal obligation.</p>

            <p>b) To protect and defend the rights or property of newloveonline.com.</p>

            <p>c) To prevent or investigate possible wrongdoing in connection with the Service.</p>

            <p>d) To protect against legal liability.</p>

            <p>&nbsp;</p>

            <p><strong>10. Security of Data</strong></p>

            <p>10.1. The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

            <p>10.2. Our Policy on "Do Not Track" Signals under the California Online Protection Act (CalOPPA)</p>

            <p>10.3. We do not support Do Not Track ("DNT").&nbsp;</p>

            <p>10.4. Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.</p>

            <p>10.5. You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>

            <p>&nbsp;</p>

            <p><strong>11. Your Data Protection Rights under the General Data Protection Regulation (GDPR)</strong></p>

            <p>11.1. If you are a resident of the European Economic Area (EEA), you have certain data protection rights.&nbsp;</p>

            <p>11.2. newloveonline.com aims to take reasonable steps to allow you to correct, amend, delete or limit the use of your Personal Data.</p>

            <p>11.3. If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>

            <p>11.4. In certain circumstances, you have the following data protection rights:</p>

            <p><strong>a) The right to access, update, or delete the information we have on you.</strong>&nbsp;Whenever made possible, you can access, update, or request the deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you.</p>

            <p><strong>b) The right of rectification.</strong>&nbsp;You have the right to have your information rectified if that information is inaccurate or incomplete.</p>

            <p><strong>c) The right to object.</strong>&nbsp;You have the right to object to our processing of your Personal Data.</p>

            <p><strong>d) The right of restriction.</strong>&nbsp;You have the right to request that we restrict the processing of your personal information.</p>

            <p><strong>e) The right to data portability.</strong>&nbsp;You have the right to be provided with a copy of the information we have on you in a structured, machine-readable, and commonly used format.</p>

            <p><strong>f) The right to withdraw consent.</strong>&nbsp;You also have the right to withdraw your consent at any time where newloveonline.com relied on your consent to process your personal information.</p>

            <p>11.5. Please note that we may ask you to verify your identity before responding to such requests.</p>

            <p>11.6. It will take some time, but no longer than 30 days, to process your request.&nbsp;</p>

            <p>11.7. Please note that modifying or deleting your personal data will only change or delete the data in our database for the purpose of future activity and management of future communications. These modifications and deletions will not change or delete the emails or personal information that we may have already forwarded to other credit/debit card companies or any other third parties, as provided above in this Privacy Policy.</p>

            <p>11.8. Restricting access to, suspending or deleting your personal data may affect your ability to use your account and your ability (as a user) to use the Services of our Website.</p>

            <p><strong><u>11.9. As soon as we receive your request to delete your personal data, it will be deleted in the shortest possible time.</u></strong></p>

            <p>11.10. In some circumstances, we may anonymize your personal data (so that it can no longer be associated with you) for research, statistical or other purposes, in such case we may use this information indefinitely without further notice.</p>

            <p>&nbsp;</p>

            <p><strong>12. Complaints</strong></p>

            <p>12.1. At any time, you may file a complaint regarding the processing, modification, usage, restriction, suspension, deletion of your personal data, etc. by sending it to <a href="mailto:support@newloveonline.com">support@newloveonline.com</a>.</p>

            <p>12.2. We are committed to responding to your complaint within 30 calendar days of receipt. Our letter will contain a comprehensive response to your complaint and information on your next steps if our response does not satisfy you.</p>

            <p>12.3. You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>

            <p>&nbsp;</p>

            <p><strong>13. Service Providers</strong></p>

            <p>13.1. We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services or assist us in analyzing how our Service is used.</p>

            <p>13.2. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

            <p>&nbsp;</p>

            <p><strong>14. Analytics</strong></p>

            <p>14.1. We may use third-party Service Providers to monitor and analyze the use of our Service.</p>

            <p><strong>Google Analytics</strong></p>

            <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.</p>

            <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.</p>

            <p>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page:&nbsp;<a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>

            <p><strong>Firebase</strong></p>

            <p>Firebase is an analytics service provided by Google Inc.</p>

            <p>You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy:&nbsp;<a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>

            <p>We also encourage you to review the Google's policy for safeguarding your data:&nbsp;<a href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>.</p>

            <p>For more information on what type of information Firebase collects, please visit the Google Privacy &amp; Terms web page:&nbsp;<a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>

            <p><strong>Piwik / Matomo</strong></p>

            <p>Piwik or Matomo is a web analytics service. You can visit their Privacy Policy page here:&nbsp;<a href="https://matomo.org/privacy-policy">https://matomo.org/privacy-policy</a></p>

            <p><strong>Clicky</strong></p>

            <p>Clicky is a web analytics service. Read the Privacy Policy for Clicky here:&nbsp;<a href="https://clicky.com/terms">https://clicky.com/terms</a></p>

            <p><strong>Statcounter</strong></p>

            <p>Statcounter is a web traffic analysis tool. You can read the Privacy Policy for Statcounter here:&nbsp;<a href="https://statcounter.com/about/legal/">https://statcounter.com/about/legal/</a></p>

            <p><strong>Flurry Analytics</strong></p>

            <p>Flurry Analytics service is provided by Yahoo! Inc.</p>

            <p>You can opt-out from Flurry Analytics service to prevent Flurry Analytics from using and sharing your information by visiting the Flurry's Opt-out page:&nbsp;<a href="https://developer.yahoo.com/flurry/end-user-opt-out/">https://developer.yahoo.com/flurry/end-user-opt-out/</a></p>

            <p>For more information on the privacy practices and policies of Yahoo!, please visit their Privacy Policy page:&nbsp;<a href="https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm">https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm</a></p>

            <p><strong>Mixpanel</strong></p>

            <p>Mixpanel is provided by Mixpanel Inc.</p>

            <p>You can prevent Mixpanel from using your information for analytics purposes by opting-out. To opt-out of Mixpanel service, please visit this page:&nbsp;<a href="https://mixpanel.com/optout/">https://mixpanel.com/optout/</a></p>

            <p>For more information on what type of information Mixpanel collects, please visit the Terms of Use page of Mixpanel:&nbsp;<a href="https://mixpanel.com/terms/">https://mixpanel.com/terms/</a></p>

            <p><strong>Unity Analytics</strong></p>

            <p>Unity Analytics is provided by Unity Technologies.</p>

            <p>For more information on what type of information Unity Analytics collects, please visit their Privacy Policy page:&nbsp;<a href="https://unity3d.com/legal/privacy-policy">https://unity3d.com/legal/privacy-policy</a></p>

            <p>Behavioral Remarketing</p>

            <p>&nbsp;newloveonline.com uses remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to our Service.</p>

            <p><strong>Google Ads (AdWords)</strong></p>

            <p>Google Ads (AdWords) remarketing service is provided by Google Inc.</p>

            <p>You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page:&nbsp;<a href="http://www.google.com/settings/ads">http://www.google.com/settings/ads</a></p>

            <p>Google also recommends installing the Google Analytics Opt-out Browser Add-on -&nbsp;<a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>&nbsp;- for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.</p>

            <p>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page:&nbsp;<a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>

            <p><strong>Bing Ads Remarketing</strong></p>

            <p>Bing Ads remarketing service is provided by Microsoft Inc.</p>

            <p>You can opt-out of Bing Ads interest-based ads by following their instructions:&nbsp;<a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</a></p>

            <p>You can learn more about the privacy practices and policies of Microsoft by visiting their Privacy Policy page:&nbsp;<a href="https://privacy.microsoft.com/en-us/PrivacyStatement">https://privacy.microsoft.com/en-us/PrivacyStatement</a></p>

            <p><strong>Twitter</strong></p>

            <p>Twitter remarketing service is provided by Twitter Inc.</p>

            <p>You can opt-out from Twitter's interest-based ads by following their instructions:&nbsp;<a href="https://support.twitter.com/articles/20170405">https://support.twitter.com/articles/20170405</a></p>

            <p>You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page:&nbsp;<a href="https://twitter.com/privacy">https://twitter.com/privacy</a></p>

            <p><strong>Facebook</strong></p>

            <p>Facebook remarketing service is provided by Facebook Inc.</p>

            <p>You can learn more about interest-based advertising from Facebook by visiting this page:&nbsp;<a href="https://www.facebook.com/help/164968693837950">https://www.facebook.com/help/164968693837950</a></p>

            <p>To opt-out from Facebook's interest-based ads, follow these instructions from Facebook:&nbsp;<a href="https://www.facebook.com/help/568137493302217">https://www.facebook.com/help/568137493302217</a></p>

            <p>Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA&nbsp;<a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>, the Digital Advertising Alliance of Canada in Canada&nbsp;<a href="http://youradchoices.ca/">http://youradchoices.ca/</a>&nbsp;or the European Interactive Digital Advertising Alliance in Europe&nbsp;<a href="http://www.youronlinechoices.eu/">http://www.youronlinechoices.eu/</a>, or opt-out using your mobile device settings.</p>

            <p>For more information on the privacy practices of Facebook, please visit Facebook's Data Policy:&nbsp;<a href="https://www.facebook.com/privacy/explanation">https://www.facebook.com/privacy/explanation</a></p>

            <p><strong>Pinterest</strong></p>

            <p>Pinterest remarketing service is provided by Pinterest Inc.</p>

            <p>You can opt-out from Pinterest's interest-based ads by enabling the "Do Not Track" functionality of your web browser or by following Pinterest instructions:&nbsp;<a href="http://help.pinterest.com/en/articles/personalization-and-data">http://help.pinterest.com/en/articles/personalization-and-data</a></p>

            <p>You can learn more about the privacy practices and policies of Pinterest by visiting their Privacy Policy page:&nbsp;<a href="https://about.pinterest.com/en/privacy-policy">https://about.pinterest.com/en/privacy-policy</a></p>

            <p><strong>AdRoll</strong></p>

            <p>AdRoll remarketing service is provided by Semantic Sugar, Inc.</p>

            <p>You can opt-out of AdRoll remarketing by visiting this AdRoll Advertising Preferences web page:&nbsp;<a href="http://info.evidon.com/pub_info/573?v=1&amp;nt=1&amp;nw=false">http://info.evidon.com/pub_info/573?v=1&amp;nt=1&amp;nw=false</a></p>

            <p>For more information on the privacy practices of AdRoll, please visit the AdRoll Privacy Policy web page:&nbsp;<a href="http://www.adroll.com/about/privacy">http://www.adroll.com/about/privacy</a></p>

            <p><strong>Perfect Audience</strong></p>

            <p>Perfect Audience remarketing service is provided by NowSpots Inc.</p>

            <p>You can opt-out of Perfect Audience remarketing by visiting these pages: Platform Opt-out (<a href="http://pixel.prfct.co/coo">http://pixel.prfct.co/coo</a>) and Partner Opt-out (<a href="http://ib.adnxs.com/optout">http://ib.adnxs.com/optout</a>).</p>

            <p>For more information on the privacy practices of Perfect Audience, please visit the Perfect Audience Privacy Policy &amp; Opt-out web page:&nbsp;<a href="https://www.perfectaudience.com/privacy/index.html">https://www.perfectaudience.com/privacy/index.html</a>&nbsp;</p>

            <p><strong>AppNexus</strong></p>

            <p>AppNexus remarketing service is provided by AppNexus Inc.</p>

            <p>You can opt-out of AppNexus remarketing by visiting the Privacy &amp; the AppNexus Platform web page:&nbsp;<u><a href="https://www.appnexus.com/platform-privacy-policy#choices">https://www.appnexus.com/platform-privacy-policy#choices</a></u></p>

            <p>For more information on the privacy practices of AppNexus, please visit the AppNexus Platform Privacy Policy web page:&nbsp;<a href="https://www.appnexus.com/platform-privacy-policy">https://www.appnexus.com/platform-privacy-policy</a></p>

            <p>&nbsp;</p>

            <p><strong>15. Payments</strong></p>

            <p>15.1. We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).</p>

            <p>15.2. We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express, and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>

            <p>15.3. The payment processors we work with are:</p>

            <p><strong>Apple Store In-App Payments</strong></p>

            <p>Their Privacy Policy can be viewed at&nbsp;<a href="https://www.apple.com/legal/privacy/en-ww/">https://www.apple.com/legal/privacy/en-ww/</a></p>

            <p><strong>Google Play In-App Payments</strong></p>

            <p>Their Privacy Policy can be viewed at&nbsp;<a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a></p>

            <p><strong>Stripe</strong></p>

            <p>Their Privacy Policy can be viewed at&nbsp;<a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a></p>

            <p><strong>FastSpring</strong></p>

            <p>Their Privacy Policy can be viewed at&nbsp;<a href="http://fastspring.com/privacy/">http://fastspring.com/privacy/</a></p>

            <p><strong>Authorize.net</strong></p>

            <p>Their Privacy Policy can be viewed at&nbsp;<a href="https://www.authorize.net/company/privacy/">https://www.authorize.net/company/privacy/</a></p>

            <p><strong>2Checkout</strong></p>

            <p>Their Privacy Policy can be viewed at&nbsp;<a href="https://www.2checkout.com/policies/privacy-policy">https://www.2checkout.com/policies/privacy-policy</a></p>

            <p><strong>Sage Pay</strong></p>

            <p>Their Privacy Policy can be viewed at&nbsp;<a href="https://www.sagepay.co.uk/policies">https://www.sagepay.co.uk/policies</a></p>

            <p><strong>Square</strong></p>

            <p>Their Privacy Policy can be viewed at&nbsp;<a href="https://squareup.com/legal/privacy-no-account">https://squareup.com/legal/privacy-no-account</a></p>

            <p><strong>Go Cardless</strong></p>

            <p>Their Privacy Policy can be viewed at&nbsp;<a href="https://gocardless.com/en-eu/legal/privacy/">https://gocardless.com/en-eu/legal/privacy/</a></p>

            <p><strong>Elavon</strong></p>

            <p>Their Privacy Policy can be viewed at&nbsp;<a href="https://www.elavon.com/privacy-pledge.html">https://www.elavon.com/privacy-pledge.html</a>&nbsp;</p>

            <p><strong>Verifone</strong></p>

            <p>Their Privacy Policy can be viewed at&nbsp;<a href="https://www.verifone.com/en/us/legal">https://www.verifone.com/en/us/legal</a></p>

            <p><strong>WeChat</strong></p>

            <p>Their Privacy Policy can be viewed at&nbsp;<a href="https://www.wechat.com/en/privacy_policy.html">https://www.wechat.com/en/privacy_policy.html</a></p>

            <p><strong>Alipay</strong></p>

            <p>Their Privacy Policy can be viewed at&nbsp;<a href="https://render.alipay.com/p/f/agreementpages/alipayglobalprivacypolicy.html">https://render.alipay.com/p/f/agreementpages/alipayglobalprivacypolicy.html</a></p>

            <p><strong>СardPay</strong></p>

            <p>Their Privacy Policy can be viewed at <a href="https://www.unlimint.com/privacy-policy/">https://www.unlimint.com/privacy-policy/</a></p>

            <p><strong>PayPal</strong></p>

            <p>Their Privacy Policy can be viewed at <a href="https://www.paypal.com/ua/webapps/mpp/ua/privacy-full">https://www.paypal.com/ua/webapps/mpp/ua/privacy-full</a></p>

            <p>&nbsp;</p>

            <p><strong>16. Links to Other Sites</strong></p>

            <p>16.1. Our Service may contain links to other sites that are not operated by us.&nbsp;</p>

            <p>16.2. If you click a third party link, you will be directed to that third party's site.&nbsp;</p>

            <p>16.3. We strongly advise you to review the Privacy Policy of every site you visit.</p>

            <p>16.4. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third party sites or services.</p>

            <p>&nbsp;</p>

            <p><strong>17. Children's Privacy</strong></p>

            <p>17.1. Our Service does not address anyone under the age of 18 ("Children").</p>

            <p>17.2. We do not knowingly collect personally identifiable information from anyone under the age of 18.&nbsp;</p>

            <p>17.3. If you are a parent or guardian and you are aware that your child has provided us with Personal Data, please contact us.&nbsp;</p>

            <p>17.4. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>

            <p>&nbsp;</p>

            <p><strong>18. Changes to This Privacy Policy</strong></p>

            <p>18.1. We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

            <p>18.2. We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>

            <p>18.3. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

            <p>&nbsp;</p>

            <p><strong>19. Contact Us</strong></p>

            <p>19.1. If you have any questions about this Privacy Policy, please contact us:</p>

            <p>By email:&nbsp;<a href="mailto:support@newloveonline.com">support@newloveonline.com</a>.</p>

            <p>By visiting this page on our website: <a href="https://newloveonline.com/">https://newloveonline.com</a>.</p>

            <p>&nbsp;</p>

            <p>Effective date: August 20, 2020</p><p></p>
        </>

    )
}