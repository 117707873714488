import React from 'react';
import * as IMAGES from "../../img";


export default () => {
    return(
        <div className={`${window.location?.pathname.includes('block1') ? 'container' : 'block-hidden'}`}>
            <div className="main-cards-grid">
                <div className="main-cards-grid__item show-animation">
                    <div className="card">
                        <div className="card__icon"><img src={IMAGES.default.card1} alt="icon"/></div>
                        <div className="card__info">
                            <div className="card__tt">Tons of free visual content</div>
                            <div className="card__txt">The variety of free photo galleries for every profile</div>
                        </div>
                    </div>
                </div>
                <div className="main-cards-grid__item show-animation">
                    <div className="card">
                        <div className="card__icon"><img src={IMAGES.default.card2} alt="icon"/></div>
                        <div className="card__info">
                            <div className="card__tt">Free video shows</div>
                            <div className="card__txt">This service will help you to see your interlocutor in real life
                                as if no distance was separating you both
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-cards-grid__item show-animation">
                    <div className="card">
                        <div className="card__icon"><img src={IMAGES.default.card3} alt="icon"/></div>
                        <div className="card__info">
                            <div className="card__tt">Passion for communication</div>
                            <div className="card__txt">All participants are longing for lasting communication</div>
                        </div>
                    </div>
                </div>
                <div className="main-cards-grid__item show-animation">
                    <div className="card">
                        <div className="card__icon"><img src={IMAGES.default.card4} alt="icon"/></div>
                        <div className="card__info">
                            <div className="card__tt">Manually verified profiles</div>
                            <div className="card__txt">All members' profiles are manually verified by our moderation
                                team
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-cards-grid__item show-animation">
                    <div className="card">
                        <div className="card__icon"><img src={IMAGES.default.card5} alt="icon"/></div>
                        <div className="card__info">
                            <div className="card__tt">Safe payments</div>
                            <div className="card__txt">Maximal safety and privacy during communication and payments
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-cards-grid__item show-animation">
                    <div className="card">
                        <div className="card__icon"><img src={IMAGES.default.card6} alt="icon"/></div>
                        <div className="card__info">
                            <div className="card__tt">24/7 support</div>
                            <div className="card__txt">Our support team is always at your disposal 24/7 to help you with
                                any issues
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-cards-grid__item show-animation">
                    <div className="card">
                        <div className="card__icon"><img src={IMAGES.default.card7} alt="icon"/></div>
                        <div className="card__info">
                            <div className="card__tt">Variety of convenient tools</div>
                            <div className="card__txt">You can use chats, letters and likes to search and communicate
                                with your interlocutor
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-cards-grid__item show-animation">
                    <div className="card">
                        <div className="card__icon"><img src={IMAGES.default.card8} alt="icon"/></div>
                        <div className="card__info">
                            <div className="card__tt">Free registration</div>
                            <div className="card__txt">We provide free registration and transparent fees</div>
                        </div>
                    </div>
                </div>
                <div className="main-cards-grid__item show-animation">
                    <div className="card">
                        <div className="card__icon"><img src={IMAGES.default.card9} alt="icon"/></div>
                        <div className="card__info">
                            <div className="card__tt">Constant access to correspondence</div>
                            <div className="card__txt">Your correspondence always stays yours. Chats and letters are
                                stored forever
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}