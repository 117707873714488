import React, {useState} from "react";

export default ({active = false, setActive, formData, setFormData}) => {

    const [checked, setChecked] = useState({key: '', activeTarget: null});

    const doCheck = (name, target) => {
        if (target.checked) {
            const oldKey = checked.key;
            const oldTarget = checked.activeTarget;
            if (oldKey !== name) {
                setChecked({...checked, ...{key: name, activeTarget: target}});

                if (oldTarget !== null) {
                    oldTarget.checked = false;
                }
            }
        }
    }

    const doOnNext = () => {
        setActive();
    }

    return (
        <>
            <div className={`box ${active ? 'active' : 'box_hidden'}`}>

                <div className="banner-box__cap banner-box__cap_grid">
                    <div className="title-site_h2-optional">Questions</div>
                    <ul className="tabs tabs-value">
                        <li className="passed">1</li>
                        <li className="passed">2</li>
                        <li className="passed">3</li>
                        <li className="current">4</li>
                        <li>5</li>
                        <li>6</li>
                        <li>7</li>
                        <li>8</li>
                    </ul>
                </div>

                <div className="banner-box__body banner-box__body_wrapp1">

                    <p className="box__txt-st">Which girls do you prefer?</p>
                    <ul className="box__body-check box__body-check_center box__body-check_big">
                        <li>
                            <label className="check">
                                <input className="check__input validate" type="radio" minLength="1"
                                       onChange={(e) => {
                                           doCheck('18 - 25 y.o.', e.target)
                                       }}/>
                                <span className="check__box"></span>
                                18 - 25 y.o.
                            </label>
                        </li>
                        <li>
                            <label className="check">
                                <input className="check__input validate" type="radio"
                                       onChange={(e) => {
                                           doCheck('26 - 34 y.o.', e.target)
                                       }}/>
                                <span className="check__box"></span>
                                26 - 34 y.o.
                            </label>
                        </li>
                        <li>
                            <label className="check">
                                <input className="check__input validate" type="radio"
                                       onChange={(e) => {
                                           doCheck('35 - 40 y.o.', e.target)
                                       }}/>
                                <span className="check__box"></span>
                                35 - 40 y.o.
                            </label>
                        </li>
                        <li>
                            <label className="check">
                                <input className="check__input validate" type="radio"
                                       onChange={(e) => {
                                           doCheck('41 - 55 y.o.', e.target)
                                       }}/>
                                <span className="check__box"></span>
                                41 - 55 y.o.
                            </label>
                        </li>
                        <li>
                            <label className="check">
                                <input className="check__input validate" type="radio"
                                       onChange={(e) => {
                                           doCheck('56 - 70 y.o.', e.target)
                                       }}/>
                                <span className="check__box"></span>
                                56 - 70 y.o.
                            </label>
                        </li>
                    </ul>

                    <div className="link-next">
                        <button type="button" className="btn btn_bold btn_lg btn_min-width tabs-next"
                                onClick={() => doOnNext()}>CONTINUE
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}