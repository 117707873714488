import axios from 'axios';
export function save(key, value) {
    if (localStorage.setItem(key, value)) return value;
    return '';
}
export function get(key) {
    return localStorage.getItem(key) || '';
}

export function checkEmail(email = null) {
    if (email === null) return false;
    // eslint-disable-next-line
    let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(email);
}

export function CheckEmailAPI(email) {
    return axios.post(`/api/login/checkEmail`, {
        email: email
    }).then(res => res.data);
}

export function Register(email, password, day = 1, month = 1, year = 1930, gender = 1, user_real_name = '', body_type = 0, age_from = 18, age_to = 100, purpose_staying = 0) {
    let tail = window.location.search;
    let click_id = '';
    let utm_netw = getUrlParameter('utm_netw');
    if (utm_netw === 'MGID'){
        click_id = getUrlParameter('tid');
    }else{
        click_id = getUrlParameter('msclkid');
    }
    return axios.post(`/api/login/registerLanding`, {
        email: email,
        password: password,
        day: day,
        month: month,
        year: year,
        gender: gender,
        user_real_name: email?.split('@')[0] ?? '',
        body_type: body_type,
        age_from: age_from,
        age_to: age_to,
        purpose_staying: purpose_staying,
        click_id: click_id,
        tail: tail,
    }).then(res => res.data);
}

export function submitForm(email, password) {
    let form = document.createElement('form');
    document.body.appendChild(form);
    form.method = 'post';
    form.action = `/landing/landing_login${window.location.search.substring()}`;
    let input = document.createElement('input');
    let input2 = document.createElement('input');
    let input3 = document.createElement('input');
    let input4 = document.createElement('input');
    input.type = 'hidden';
    input2.type = 'hidden';
    input3.type = 'hidden';
    input4.type = 'hidden';
    input.name = 'email';
    input2.name = 'password';
    input3.name = 'utm_land';
    input4.name = 'tail';
    input.value = email;
    input2.value = password;
    input3.value = getUrlParameter('utm_land');
    input4.value = getTail();
    form.appendChild(input);
    form.appendChild(input2);
    form.appendChild(input3);
    form.appendChild(input4);
    form.submit();
}

export function getTail() {
    return window?.location?.search ?? '';
}

export function getUrlParameter(sParam) {
    let sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
}
export function GoogleAuthMethod(data) {
    let click_id = '';
    let utm_netw = getUrlParameter('utm_netw');
    if (utm_netw === 'MGID'){
        click_id = getUrlParameter('tid');
    }else{
        click_id = getUrlParameter('msclkid');
    }
    data.click_id = click_id;
    return axios.post(`https://sofiadate.com/api/socialAuth/googleAuth`, data)
        .then(res => res.data);
}
export function FacebookAuthMethod(data) {
    let click_id = '';
    let utm_netw = getUrlParameter('utm_netw');
    if (utm_netw === 'MGID'){
        click_id = getUrlParameter('tid');
    }else{
        click_id = getUrlParameter('msclkid');
    }
    data.click_id = click_id;
    return axios.post(`https://sofiadate.com/api/socialAuth/fbAuth`, data)
        .then(res => res.data);
}

export function Login(token) {
    save('token', JSON.stringify(token));
    window.location.href = `/chat?registration=success&${window.location.search.substring()}`;
}
export function getArrayOfNumbers(start, stop) {
    const result = [];
    while (start <= stop) {
        result.push(start++);
    }
    return result;
}


export function checkDate(year, month, day) {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const dob = new Date(year, month - 1, day);
    const dobnow = new Date(today.getFullYear(), dob.getMonth(), dob.getDate());
    let age;

    age = today.getFullYear() - dob.getFullYear();

    if (today < dobnow) {
        age = age - 1;
    }

    return +age >= 18;
}