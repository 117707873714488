import React, {useState} from "react";
import {checkEmail} from "../../../../lnd2/methods";
import {CheckEmailAPI} from "../../../common/Modals/MainModal";

export default ({active=false, setActive, formData, setFormData}) => {
    const [inputValid, setInputValid] = useState(true);
    const [emailUnique, setEmailUnique] = useState(true);

    const handleNext = async () => {
        if (formData.email.trim() === '') {
            setInputValid(false);
            return false;
        }

        if (!checkEmail(formData.email)) {
            setInputValid(false);
            return false;
        }

        const isEmailInUse = (await CheckEmailAPI(formData.email))?.status;
        if (isEmailInUse) {
            setEmailUnique(false);
            return false;
        }

        setActive();
    };

    return (
        <>
            <div className={`box ${active ? 'active' : 'box_hidden'}`}>

                <div className="banner-box__cap banner-box__cap_grid">
                    <div className="title-site_h2-optional">Registration</div>
                    <ul className="tabs tabs-value">
                        <li className="passed">1</li>
                        <li className="passed">2</li>
                        <li className="passed">3</li>
                        <li className="passed">4</li>
                        <li className="passed">5</li>
                        <li className="passed">6</li>
                        <li className="current">7</li>
                        <li>8</li>
                    </ul>
                </div>

                <div className="banner-box__body banner-box__body_wrapp1">
                    <div className='name_gap'/>
                    <p className="box__txt-st">Type in your email</p>
                    <div className="form-group form-group_sm">
                        <input className="form-group__field form-group-name__field validate" type="text" placeholder="Enter your email"
                               onKeyUp={(e) => {
                                   if (e.key === 'Enter') {
                                       handleNext();
                                   }
                               }}
                               onChange={(e) => setFormData({...formData, ...{email: e.target.value}})} />
                    </div>
                    <div className={`input-label__suggestion error_color margin-top__10px ${!inputValid ? 'error_color' : 'box_hidden'}`}>Invalid email</div>
                    <div className={`input-label__suggestion error_color margin-top__10px ${!emailUnique ? 'error_color' : 'box_hidden'}`}>This email is in use</div>
                    <div className="link-next">
                        <button type="button" className="btn btn_bold btn_lg btn_min-width__no-margin tabs-next"
                                onClick={handleNext}>NEXT</button>
                    </div>
                </div>
            </div>
        </>
    )
}