import React, {useState} from "react";

export default ({active=false, setActive, formData, setFormData}) => {

    const [inputValid, setInputValid] = useState(true);

    const handleNext = () => {
        if (isValid(formData.age)) {
            setActive();
        } else {
            setInputValid(false);
        }
    }

    const doSetFormData = (target) => {
        const value = target.value;

        if (value.length > 2) {
            target.value = value.substring(0, 2);
        } else {
            setFormData({...formData, ...{age: value}});
        }
    }

    function isValid(age) {
        if (age.length === 0) {
            return false;
        }

        const num = parseInt(age);
        return num >= 24 && num <= 99
    }

    return (
        <>
            <div className={`box ${active ? 'active' : 'box_hidden'}`}>

                <div className="banner-box__cap banner-box__cap_grid">
                    <div className="title-site_h2-optional">Registration</div>
                    <ul className="tabs tabs-value">
                        <li className="passed">1</li>
                        <li className="passed">2</li>
                        <li className="passed">3</li>
                        <li className="passed">4</li>
                        <li className="current">5</li>
                        <li>6</li>
                        <li>7</li>
                        <li>8</li>
                    </ul>
                </div>

                <div className="banner-box__body banner-box__body_wrapp1">
                    <div className='age_gap'/>
                    <div className="form-group form-group_sm-push form-group_year">
                        <div className="box-preview__desc">Understanding your real age will help to find the best matches for you</div>
                        <input className="form-group__field validate" type="number" min="24" max="99" placeholder="Enter your age"
                               onKeyUp={(e) => {
                                   if (e.key === 'Enter') {
                                       handleNext();
                                   }
                               }}
                                onChange={(e) => doSetFormData(e.target)}/>
                    </div>
                    <div className={`input-label__suggestion error_color margin-top__10px ${!inputValid ? 'error_color' : 'box_hidden'}`}>Your age should be more than 24</div>
                    <div className="link-next">
                        <button type="button" className="btn btn_bold btn_lg btn_min-width tabs-next"
                                onClick={handleNext}>NEXT</button>
                    </div>
                </div>
            </div>
        </>
    )
}