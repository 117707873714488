import React from "react";

export default ({active = false, setActive, formData, setFormData}) => {

    return (
        <>
            <div className={`box ${active ? 'active' : 'box_hidden'}`}>
                <div className="banner-box__cap banner-box__cap_grid">
                    <div className="title-site_h2-optional">Questions</div>
                    <ul className="tabs tabs-value">
                        <li className="passed">1</li>
                        <li className="current">2</li>
                        <li>3</li>
                        <li>4</li>
                        <li>5</li>
                        <li>6</li>
                        <li>7</li>
                        <li>8</li>
                    </ul>
                </div>

                <div className="banner-box__body banner-box__body_wrapp1">

                    <p className="box__txt-st"><b>Are you okay</b> with the <b>Woman</b> making the first move?</p>
                    <div className="group-btn">
                        <button type="button" className="btn btn_lg btn_bold tabs-next-novalidate"
                                onClick={() => setActive()}>YES
                        </button>
                        <button type="button" className="btn btn_danger btn_lg btn_bold tabs-next-novalidate"
                                onClick={() => setActive()}>NO
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}