import React from "react";
import TabAge from './TabAge';
import TabName from './TabName';
import TabEmail from './TabEmail';
import TabGender from './TabGender';
import TabRelationship from './TabRelationship'
import TabRelationship2 from './TabRelationship2'
import TabPreferences from './TabPreferences'
import TabPreferences2 from './TabPreferences2'
import TabPassword from './TabPassword';
// import Social from '../../Social';
import LoaderModal from '../../common/Modals/LoaderModal';
// import * as IMAGES from "../../../img";
import useInitBlockForm from 'lnd-worker/src/hooks/block/useInitBlockForm';
// import useSocialFacebook from 'lnd-worker/src/hooks/social/useSocialFacebook';
// import useSocialGoogle from 'lnd-worker/src/hooks/social/useSocialGoogle';

export default () => {
    const initStateFormData = {gender: '', user_real_name: '', age: '', password: '', email: ''};
    // const {authenticate} = useSocialFacebook();
    // const {responseGoogle} = useSocialGoogle();
    const {
        active,
        doSetActive,
        formData,
        setFormData,
        onSubmit,
        saveAge,
        // saveIndexedHistory,
        modalIsActiveLoader,
        saveHistory,
    } = useInitBlockForm(initStateFormData);

    return (
        <>
        <div className="bl-tabs">
            <div className="tabs-info" id="formTabs">
                <TabGender
                    active={active === 1}
                    setActive={() => doSetActive(2)}
                    saveHistory={saveHistory}
                    setFormData={setFormData}
                    formData={formData}
                />
                <TabRelationship
                    active={active === 2}
                    setActive={() => doSetActive(3)}
                    setFormData={setFormData}
                    formData={formData}
                />
                <TabRelationship2
                    active={active === 3}
                    setActive={() => doSetActive(4)}
                    setFormData={setFormData}
                    formData={formData}
                />
                <TabPreferences
                    active={active === 4}
                    setActive={() => doSetActive(5)}
                    setFormData={setFormData}
                    formData={formData}
                />
                <TabPreferences2
                    active={active === 5}
                    setActive={() => doSetActive(6)}
                    setFormData={setFormData}
                    formData={formData}
                />
                <TabAge
                    active={active === 6}
                    setActive={() => {
                        saveAge();
                        if (window.location.href.includes('newloveonline')) {
                            try {
                                window.dataLayer.push({'event': 'sof', 'eventCategory': 'send', 'eventAction': 'form', 'eventLabel': 'age'});
                            } catch (e) {
                                console.warn(e);
                            }
                        }
                    }}
                    setFormData={setFormData}
                    formData={formData}
                />
                <TabName
                    active={active === 7}
                    setActive={() => {
                        doSetActive(8);
                        if (window.location.href.includes('newloveonline')) {
                            try {
                                window.dataLayer.push({'event': 'sof', 'eventCategory': 'send', 'eventAction': 'form', 'eventLabel': 'name'});
                            } catch (e) {
                                console.warn(e);
                            }
                        }
                    }}
                    setFormData={setFormData}
                    formData={formData}
                />
                <TabEmail
                    active={active === 8}
                    setActive={() => {
                        doSetActive(9);
                        if (window.location.href.includes('newloveonline')) {
                            try {
                                window.dataLayer.push({'event': 'sof', 'eventCategory': 'send', 'eventAction': 'form', 'eventLabel': 'email'});
                            } catch (e) {
                                console.warn(e);
                            }
                        }
                    }}
                    setFormData={setFormData}
                    formData={formData}
                />
                <TabPassword
                    active={active === 9}
                    setActive={() => onSubmit()}
                    setFormData={setFormData}
                    formData={formData}
                />
            </div>

            {/* <Social
                IMAGES={IMAGES}
                active={active > 7}
                saveParam={saveIndexedHistory}
                responseGoogle={responseGoogle}
                authenticate={authenticate}
            /> */}

            <div className={`${active > 1 ? 'gap' : ''}`}/>
        </div>
            <LoaderModal modalIsActive={modalIsActiveLoader}/>
        </>
    )
}