import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import {Route, BrowserRouter} from 'react-router-dom';
import * as NAVIGATION from 'lnd-worker/src/constants/navigation';

export const LND_PREFFIX = `${NAVIGATION.LND_PREFIX.length ? `/${NAVIGATION.LND_PREFIX}` : ''}`;

ReactDOM.render(
    <BrowserRouter>
        <Route exact path={LND_PREFFIX} component={App}/>
        {/* <Route exact path={`${LND_PREFFIX}/:block`} component={App}/> */}
    </BrowserRouter>,
    document.getElementById('root')
);
