import React, { useEffect, useState } from 'react';

import styles from '../styles/index.module.css';

import * as MENU from "../constants";
import * as IMAGES from '../img';
import PageApi from "../api/PageApi";
import PrivacyPolicy from './PrivacyPolicy';
import CookiePolicy from './CookiePolicy';
import TermsAndConditions from './TermsAndConditions';

export default ({ pageName = null, close }) => {
    const [page, setPage] = useState(null);
    const [title, setTitle] = useState('');

    useEffect(() => {
        MENU.ARRAY_STATIC_PAGE.includes(pageName)
            && new PageApi().postShow(pageName).then(res => {
                if (res.status) {
                    res?.result?.content && setPage(res.result.content);
                    res?.result?.title && setTitle(res.result.title);
                }
            });
    }, [pageName]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        (title && page)
            ? <div className={styles.term_popup_wrap}>
                <div className={styles.term_popup_close}>
                    <img src={IMAGES.close} alt="" onClick={close} />
                </div>
                <div className={styles.term_popup_title}>{title}</div>
                {/* <div className={styles.term_popup_content} dangerouslySetInnerHTML={{__html: page}}/> */}
                <div className={styles.term_popup_content}>
                    {title === 'Cookie Policy' && <CookiePolicy />}
                    {title === 'Privacy Policy' && <PrivacyPolicy />}
                    {title === 'Terms and Conditions' && <TermsAndConditions />}
                </div>
            </div>
            : <img src={IMAGES.loading} alt={'Loading'} className={'loader'} />

    )
}
