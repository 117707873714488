import React, {useState} from "react";

export default ({active = false, setActive, formData, setFormData}) => {

    const [passValid, setPassValid] = useState(true);

    const handleNext = () => {
        if (formData.password.length < 6) {
            setPassValid(false);
            return false;
            // eslint-disable-next-line no-useless-escape
        }
        setActive();
    };

    return (
        <>
            <div className={`box ${active ? 'active' : 'box_hidden'}`}>
                <div className="banner-box__cap banner-box__cap_grid">
                    <div className="title-site_h2-optional">Final step</div>
                    <ul className="tabs tabs-value">
                        <li className="passed">1</li>
                        <li className="passed">2</li>
                        <li className="passed">3</li>
                        <li className="passed">4</li>
                        <li className="passed">5</li>
                        <li className="passed">6</li>
                        <li className="passed">7</li>
                        <li className="current">8</li>
                    </ul>
                </div>

                <div className="banner-box__body banner-box__body_wrapp1">
                    <div className="form-group form-group_sm">
                        <p className="box__txt-st">Enter your password</p>
                        <input id="password" className="form-group__field form-group-name__field validate" type="text"
                               placeholder="Your password"
                               value={formData.password}
                               onKeyUp={(e) => {
                                   if (e.key === 'Enter') {
                                       handleNext();
                                   }
                               }}
                               onChange={(e) => {
                                   setFormData({...formData, ...{password: e.target.value}});
                               }}/>
                    </div>
                    <div className={`input-label__suggestion ${passValid ? '' : 'error_color'}`}>Minimum 6 characters</div>
                    <div className="link-next">
                        <button type="button" className="btn btn_bold btn_lg btn_min-width__no-margin tabs-next"
                                onClick={handleNext}>Find your matches
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}