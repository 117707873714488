import React, {useState} from "react";

export default ({active = false, setActive, formData, setFormData}) => {

    const initStateCheckedData = {data: []}

    const [checked, setChecked] = useState(initStateCheckedData);

    const doCheck = (key, target) => {

        const isChecked = target.checked;

        if (checked.data.length >= 3 && isChecked === true) {
            target.checked = false;
            return;
        }

        if (isChecked) {
            if (!checked.data.includes(key)) {
                setChecked({...checked, ...{data: [...checked.data, key]}})
            }
        } else {
            const filtered = checked.data.filter(k => k !== key);
            setChecked({...checked, ...{data: filtered}});
        }
    }

    const doOnNext = () => {
        setActive();
    }

    return (
        <>
            <div className={`box ${active ? 'active' : 'box_hidden'}`}>

                <div className="banner-box__cap banner-box__cap_grid">
                    <div className="title-site_h2-optional">Questions</div>
                    <ul className="tabs tabs-value">
                        <li className="passed">1</li>
                        <li className="passed">2</li>
                        <li className="current">3</li>
                        <li>4</li>
                        <li>5</li>
                        <li>6</li>
                        <li>7</li>
                        <li>8</li>
                    </ul>
                </div>

                <div className="banner-box__body banner-box__body_wrapp1">
                    <p className="box__txt-st">What kind of body do you prefer?</p>
                    <div className='flex-wrapper'>
                    <ul className="box__body-check box__body-check_big">
                        <li>
                            <label className="check">
                                <input className="check__input validate" type="checkbox" minLength="3"
                                       onChange={(e) => {
                                           doCheck('Skinny', e.target);
                                       }}/>
                                <span className="check__box"></span>
                                Skinny
                            </label>
                        </li>
                        <li>
                            <label className="check">
                                <input className="check__input validate" type="checkbox"
                                       onChange={(e) => {
                                           doCheck('Athletic', e.target);
                                       }}/>
                                <span className="check__box"></span>
                                Athletic
                            </label>
                        </li>
                        <li>
                            <label className="check">
                                <input className="check__input validate" type="checkbox"
                                       onChange={(e) => {
                                           doCheck('Average', e.target);
                                       }}/>
                                <span className="check__box"></span>
                                Average
                            </label>
                        </li>
                        <li>
                            <label className="check">
                                <input className="check__input validate" type="checkbox"
                                       onChange={(e) => {
                                           doCheck('BBW', e.target);
                                       }}/>
                                <span className="check__box"></span>
                                BBW (Big Beautiful Woman)
                            </label>
                        </li>
                    </ul>
                    </div>
                    <div className="link-next">
                        <button type="button" className="btn btn_bold btn_lg btn_min-width tabs-next"
                                onClick={() => doOnNext()}>NEXT
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}