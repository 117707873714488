import React from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import MainBlock from './components/MainBlock';
import useInitBlock from 'lnd-worker/src/hooks/block/useInitBlock';
import { useCheckValidToken } from 'lnd-worker/src/hooks/token/useCheckValidToken';

function App() {
    useCheckValidToken();
    
    useInitBlock();
    
    return (
        <>
            <div id="wrapper">
                <Header/>
                <MainBlock/>
                <Footer/>
            </div>
        </>
    );
}

export default App;
